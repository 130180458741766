.hero-component {
  align-items: center;

  .top-text,
  .items,
  .bottom-text {
    margin-bottom: 2rem;
  }

  .bottom-text {
    // font-style: italic;
    font-size: 1.4rem;
  }

  .text-container h1.containing-animated-text {
    min-height: 18rem;
  }

  .button-component {
    display: block;
    margin: 0 auto 2rem auto;
  }

  // .items {
  //   // .quality {
  //   //   text-align: center;
  //   // }
  // }
  img {
    width: 100%;
  }

  @media (min-width: 428px) {
    .text-container h1.containing-animated-text {
      min-height: 11rem;
    }
  }

  @media (min-width: 768px) {
    .flex-container {
      display: flex;
      justify-content: space-between;
    }

    .text-container {
      width: 65%;

      h1.containing-animated-text {
        min-height: 25rem;
      }

      .button-component {
        margin: 3rem 0;

        &.hero-btn {
          margin-bottom: 1rem;
        }
      }
    }

    img {
      margin-left: 2rem;
      max-height: 409px;
      width: unset;
    }
  }

  @media (min-width: 992px) {
    .text-container h1.containing-animated-text {
      min-height: 15rem;
    }
  }
}
