.coaching-first-section {
  h5 {
    color: $marina-grey;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 3rem;
  }

  p {
    margin-bottom: 0;
  }
}

.coaching-second-section {
  .coaching-approach-img {
    display: block;
    width: 90vw;
    max-width: 78rem;
    margin: 0 auto;
  }
}

.coaching-third-section {
  h5 {
    color: $marina-grey;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 3rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    .text-with-image {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        max-width: 78rem;
      }
    }
    .bottom-text {
      max-width: 100rem;
    }
  }
}

.coaching-fourth-section {
  .logo {
    display: block;
    margin-top: 3rem;
  }
}
