.testimony-component {
  .content,
  .function {
    color: #a1a1b0;
  }

  .content {
    margin-bottom: 1.7rem;
  }

  .function {
    font-size: 1.3rem;
  }

  .testimony {
    display: flex;
    img {
      border-radius: 50%;
      margin-right: 2rem;
    }
  }

  .testimonies-mobile {
    margin-top: 2.7rem;
    .testimony {
      margin-bottom: 4rem;
    }

    .content {
      font-size: 1.4rem
    }

    .function {
      font-size: 1.2rem;
    }
  }

  .testimonies-desktop {
    display: none;

    .swiper-slide {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 2.5rem 2rem;
      margin-top: 6rem;
    }


    .swiper-pagination {
      position: relative;
      margin-top: 2rem;
    }
  }

  @media (min-width: 768px) {
    .testimonies-desktop {
      display: block;
    }
    .testimonies-mobile {
      display: none;
    }
  }
}

.linkedin_icon img {
  height: 32px;
  margin-top: -10px;
  margin-left: -10px;
}
