// Color scheme
$marina-blue:#021B3D;
$marina-blue-active:#1c70e6;
$marina-yellow:#DD960C;
$marina-green:#009289;
$marina-mouse-grey:#9F9FAF;
$marina-grey:#616161;
$marina-light-grey:#FAFBFD;
$marina-white:#FFFFFF;



// /* FONTS */
$montserrat: "Montserrat", sans-serif;
$allura: "Allura", cursive;
$windsong: "WindSong", cursive;
$fsizebase: 1.6rem;


$font-size-base: $fsizebase;

// $flight: 300;
// $fregular: 400;
// $fmedium: 500;
// $fsemibold: 600;
// $fbold: 700;
// $fextrabold: 800;
// $fblack: 900;

// /* navbar */
$navbar-height: 6.4rem;
