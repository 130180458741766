footer {
  background-color: $marina-light-grey;
  color: $marina-blue;
  padding-top: 6rem;
  padding-bottom: 2rem;

  .footer-top {
    border-bottom: 0.1rem solid #c8c9ca;
    padding-bottom: 5rem;
    .slogan {
      margin-bottom: 3rem;
      max-width: 29rem;
      .marina-serr {
        font-size: 2.4rem;
        font-weight: 700;
      }
      .marina-slogan {
        font-size: 2.6rem;
      }
    }

    .faq {
      .title {
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: 1.4rem;
        &.marina {
          margin-bottom: 0rem;
        }
      }
    }
  }

  .footer-bottom {
    margin-top: 1rem;
    p, a {
      font-size: 1.4rem;
      color: $marina-blue;
    }

    a {
      text-decoration: none;
      &:hover {
        color: $marina-blue-active
      }
    }

    .bootom-right {
      a {
        margin-left: 3rem;
      }
    }
  }

  @media (min-width: 768px) {
    .footer-top {
      display: flex;
      justify-content: space-between;
      .slogan {
        margin-bottom: 0rem;
      }
    }
  }
}
