.section {
  display: flex;
  align-items: center;
  padding: 7rem 0;

  @media (max-height: 800px) {
    min-height: calc(100vh - $navbar-height);
  }
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  .item-title {
    // capitalize the text
    text-transform: capitalize;
    font-weight: 600;
    color: $marina-blue;
    text-align: center;
    margin-bottom: 3rem
  }

  .custom-list {
    list-style-type: none;
    padding: 0;
    font-size: smaller;
    li {
      margin-bottom: 1rem;
    }
  }

  .custom-list li::before {
    content: '✨';
    margin-right: 5px; /* Adjust spacing as needed */
  }
}

@media (max-width: 767px) {
  .custom-grid {
    grid-template-columns: 1fr;
  }
}

