/* global */
@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $marina-blue;
}

h1 {
  font-weight: 700;
  margin: 2rem 0;
  font-size: 3rem;

  &.is-centered {
    text-align: center;
  }
}

p.is-centered {
  text-align: center;
}

/* colors */
.text-blue {
  color: $marina-blue;
}

.text-yellow {
  color: $marina-yellow;
}

.text-green {
  color: $marina-green;
}

.text-grey {
  color: $marina-grey;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.mb-6 {
  margin-bottom: 6rem;
}

.in-italic {
  font-style: italic;
}

.fs-13 {
  font-size: 1.3rem;
}

.quote {
  font-family: "Allura", cursive;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 3rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }
}
