.values-component {
  .desktop-values {
    display: none;
  }
  .mobile-values {
    display: block;
  }
  h1 {
    font-weight: 700;
    margin: 2rem 0;
    font-size: 3rem;
  }
  .swiper-wrapper {
    margin-bottom: 4rem;
  }

  h4 {
    font-family: $allura;
    font-size: 2.6rem;
  }

  .value-text {
    color: $marina-mouse-grey;
  }

  @media (min-width: 768px) {
    .desktop-values {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 10px;
    }
    .mobile-values {
      display: none;
    }
    h1 {
      font-size: 4rem;
    }
    .swiper-wrapper {
      margin-bottom: 0;
    }

    h4 {
      font-size: 3.6rem;
    }
  }
}
