.popup {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.96);
  color: $marina-blue;
  display: flex;
  height: 100vh;
  padding-top: 5rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;

  &.hide-popup {
    display: none;
  }

  .close-popup {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  .content {
    height: fit-content;
    max-height: 100%;
    overflow-y: scroll;
    .paragraph {
      p {
        margin: 0;
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
