#custom-navbar {
  padding: 0;
  a {
    text-decoration: none;
  }

  .navbar-desktop {
    background-color: $marina-light-grey;
    display: none;
    height: $navbar-height;
    top: 0; /* Position the navbar at the top of the page */
    position: fixed; /* Set the navbar to fixed position */
    width: 100%; /* Full width */
    z-index: 2;
    .navbar-right {
      width: 60vw;

      .nav-entries {
        font-weight: 500;
        font-size: 1.3rem;

        a {
          color: $marina-blue;
          &:hover {
            color: $marina-blue-active;
          }
          &:focus {
            outline: none;
          }
        }
        .entry-container {
          position: relative;
          .main-entry.active {
            padding-bottom: 0.6rem;
            border-bottom: solid 0.17rem $marina-blue;
          }
          .child-container {
            background-color: $marina-light-grey;
            position: absolute;
            top: 3rem;
            box-shadow: rgb(0 0 0 / 11.4%) 0.1rem 0.4rem 0.5rem;
            .child-entry {
              display: block;
              &:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }

  .navbar-mobile {
    background-color: $marina-light-grey;
    display: flex;
    height: $navbar-height;
    top: 0; /* Position the navbar at the top of the page */
    position: fixed; /* Set the navbar to fixed position */
    width: 100vw;
    z-index: 2;

    .side-menu {
      left: 0;
      top: 0; /* Position the navbar at the top of the page */
      position: fixed; /* Set the navbar to fixed position */
      transform: translate3d(-100vw, 0, 0);
      transition: transform 330ms ease-in-out;
      width: 100vw;
      overflow-y: scroll;
      height: 100%;
      background-color: $marina-light-grey;
      &.visible {
        transform: translate3d(0vw, 0, 0);
      }
      .close-menu {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
      }
      .nav-entries {
        margin-top: 8rem;
        font-weight: 600;
        font-size: 1.7rem;
        a {
          color: $marina-blue;
          &:focus {
            outline: none;
          }
        }
        .entry-container {
          margin-bottom: 2rem;
          .main-entry.active {
            padding-bottom: 0.6rem;
            border-bottom: solid 0.2rem $marina-blue;
          }
          .chevron img {
            transition: transform 140ms ease-in-out;
            transform: rotate(90deg);
          }
          .chevron.rotated img {
            transform: rotate(270deg);
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .navbar-desktop {
      display: flex;
    }

    .navbar-mobile {
      display: none;
    }
  }
}
