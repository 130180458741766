@import "variables";

html {
  box-sizing: border-box; /* natural box layout model to all elements */
  font-size: 62.5%; /* for easy rem sizing: 1rem = 10px */
}

/* Body */
body {
  color: $marina-grey;
  background-color: $marina-white;
  font-family: $montserrat;
  font-size: $fsizebase;
  font-style: normal;
  font-weight: normal;

  .main-container {
    // min-height: calc(100vh - $navbar-height);
    padding-top: $navbar-height;
  }

  .bg-marina-light-grey {
    background-color: $marina-light-grey;
  }

  .bg-marina-white {
    background-color: $marina-white;
  }

  .mentions-legales {
    p {
      text-align: justify;
    }
  }

  .badge-tag {
    background-color: $marina-grey;
    font-weight: 300;
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) {

}
@media (min-width: 992px) {

}
@media (min-width: 1200px) {

}

.bg-marina-blue {
  background-color: $marina-blue;
}

.bg-marina-yellow {
  background-color: $marina-yellow;
}

.bg-marina-green {
  background-color: $marina-green;
}

.bg-marina-grey {
  background-color: $marina-grey;
}

.bg-marina-light-grey {
  background-color: $marina-light-grey;
}
