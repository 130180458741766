.section-with-squares-component {

  @keyframes shakebtn {
    0% {
      transform: translateX(0rem) rotate(0deg);
    }

    20% {
      transform: translateX(-0.2rem) rotate(-2deg);
    }

    40% {
      transform: translateX(-0.1rem) rotate(-1deg);
    }

    60% {
      transform: translateX(0.2rem) rotate(2deg);
    }

    80% {
      transform: translateX(0.1rem) rotate(1deg);
    }

    100% {
      transform: translateX(0rem) rotate(0deg);
    }
  }

  .square-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    .square {
      align-items: center;
      border: 0.1rem solid $marina-blue;
      border-radius: 0.4rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 4rem;
      padding: 1rem;
      text-decoration: none;
      width: 80%;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      &.clickable:hover {
        animation: shakebtn 0.2s 1;
        box-shadow: rgba(0, 0, 0, 0.44) 0rem 0.3rem 0.8rem;
      }
      .square-content {
        color: $marina-blue;
        font-weight: 700;
      }
    }
  }

  @media (min-width: 768px) {
    .square-container {
      flex-direction: row;
      justify-content: space-between;

      .square {
        width: 30%;
      }
    }
  }
}
