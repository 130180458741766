.button-component {
  border: none;
  border-radius: 0.4rem;
  font-weight: 600;
  text-decoration: none;
  width: fit-content;
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
  &.is-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
